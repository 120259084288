<script lang="ts">import { onMount, onDestroy } from 'svelte';
import { Replayer, unpack, mirror } from 'rrweb';
;
import { inlineCss, openFullscreen, exitFullscreen, isFullscreen, onFullscreenChange, typeOf, } from './utils';
import Controller from './Controller.svelte';
export let width = 1024;
export let height = 576;
export let events = [];
export let skipInactive = true;
export let autoPlay = true;
export let speedOption = [1, 2, 4, 8];
export let speed = 1;
export let showController = true;
export let tags = {};
export const getMirror = () => mirror;
const controllerHeight = 80;
let player;
let frame;
let replayer;
let fullscreenListener;
let _width = width;
let _height = height;
let controller;
let style;
$: style = inlineCss({
    width: `${width}px`,
    height: `${height}px`,
});
let playerStyle;
$: playerStyle = inlineCss({
    width: `${width}px`,
    height: `${height + (showController ? controllerHeight : 0)}px`,
});
const updateScale = (el, frameDimension) => {
    const widthScale = width / frameDimension.width;
    const heightScale = height / frameDimension.height;
    el.style.transform =
        `scale(${Math.min(widthScale, heightScale, 1)})` +
            'translate(-50%, -50%)';
};
export const triggerResize = () => {
    updateScale(replayer.wrapper, {
        width: replayer.iframe.offsetWidth,
        height: replayer.iframe.offsetHeight,
    });
};
export const toggleFullscreen = () => {
    if (player) {
        isFullscreen() ? exitFullscreen() : openFullscreen(player);
    }
};
export const addEventListener = (event, handler) => {
    replayer.on(event, handler);
    switch (event) {
        case 'ui-update-current-time':
        case 'ui-update-progress':
        case 'ui-update-player-state':
            controller.$on(event, ({ detail }) => handler(detail));
        default:
            break;
    }
};
export const addEvent = (event) => {
    replayer.addEvent(event);
};
export const getMetaData = () => replayer.getMetaData();
export const getReplayer = () => replayer;
// by pass controller methods as public API
export const toggle = () => {
    controller.toggle();
};
export const setSpeed = (speed) => {
    controller.setSpeed(speed);
};
export const toggleSkipInactive = () => {
    controller.toggleSkipInactive();
};
export const play = () => {
    controller.play();
};
export const pause = () => {
    controller.pause();
};
export const goto = (timeOffset) => {
    controller.goto(timeOffset);
};
onMount(() => {
    // runtime type check
    if (speedOption !== undefined && typeOf(speedOption) !== 'array') {
        throw new Error('speedOption must be array');
    }
    speedOption.forEach((item) => {
        if (typeOf(item) !== 'number') {
            throw new Error('item of speedOption must be number');
        }
    });
    if (speedOption.indexOf(speed) < 0) {
        throw new Error(`speed must be one of speedOption,
        current config:
        {
          ...
          speed: ${speed},
          speedOption: [${speedOption.toString()}]
          ...
        }
        `);
    }
    replayer = new Replayer(events, Object.assign({ speed, root: frame, unpackFn: unpack }, $$props));
    replayer.on('resize', (dimension) => {
        updateScale(replayer.wrapper, dimension);
    });
    fullscreenListener = onFullscreenChange(() => {
        if (isFullscreen()) {
            setTimeout(() => {
                _width = width;
                _height = height;
                width = player.offsetWidth;
                height = player.offsetHeight;
                updateScale(replayer.wrapper, {
                    width: replayer.iframe.offsetWidth,
                    height: replayer.iframe.offsetHeight,
                });
            }, 0);
        }
        else {
            width = _width;
            height = _height;
            updateScale(replayer.wrapper, {
                width: replayer.iframe.offsetWidth,
                height: replayer.iframe.offsetHeight,
            });
        }
    });
});
onDestroy(() => {
    fullscreenListener && fullscreenListener();
});
</script>

<style global>:global(.replayer-wrapper){position:relative}:global(.replayer-mouse){position:absolute;width:20px;height:20px;transition:.05s linear;background-size:contain;background-position:50%;background-repeat:no-repeat;background-image:url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjMwMCIgd2lkdGg9IjMwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBkYXRhLW5hbWU9IkxheWVyIDEiIHZpZXdCb3g9IjAgMCA1MCA1MCI+PHBhdGggZD0iTTQ4LjcxIDQyLjkxTDM0LjA4IDI4LjI5IDQ0LjMzIDE4YTEgMSAwIDAwLS4zMy0xLjYxTDIuMzUgMS4wNmExIDEgMCAwMC0xLjI5IDEuMjlMMTYuMzkgNDRhMSAxIDAgMDAxLjY1LjM2bDEwLjI1LTEwLjI4IDE0LjYyIDE0LjYzYTEgMSAwIDAwMS40MSAwbDQuMzgtNC4zOGExIDEgMCAwMC4wMS0xLjQyem0tNS4wOSAzLjY3TDI5IDMyYTEgMSAwIDAwLTEuNDEgMGwtOS44NSA5Ljg1TDMuNjkgMy42OWwzOC4xMiAxNEwzMiAyNy41OEExIDEgMCAwMDMyIDI5bDE0LjU5IDE0LjYyeiIvPjwvc3ZnPg==")}:global(.replayer-mouse:after){content:"";display:inline-block;width:20px;height:20px;border-radius:10px;background:#4950f6;transform:translate(-10px,-10px);opacity:.3}:global(.replayer-mouse.active:after){animation:click .2s ease-in-out 1}:global(.replayer-mouse-tail){position:absolute;pointer-events:none}@keyframes -global-click{0%{opacity:.3;width:20px;height:20px;border-radius:10px;transform:translate(-10px,-10px)}50%{opacity:.5;width:10px;height:10px;border-radius:5px;transform:translate(-5px,-5px)}}:global(.rr-player) {
    position: relative;
    background: white;
    float: left;
    border-radius: 5px;
    box-shadow: 0 24px 48px rgba(17, 16, 62, 0.12);
  }:global(.rr-player__frame) {
    overflow: hidden;
  }:global(.replayer-wrapper) {
    float: left;
    clear: both;
    transform-origin: top left;
    left: 50%;
    top: 50%;
  }:global(.replayer-wrapper) > :global(iframe) {
    border: none;
  }</style>

<div class="rr-player" bind:this={player} style={playerStyle}>
  <div class="rr-player__frame" bind:this={frame} {style} />
  {#if replayer}
    <Controller
      bind:this={controller}
      {replayer}
      {showController}
      {autoPlay}
      {speedOption}
      {skipInactive}
      {tags}
      on:fullscreen={() => toggleFullscreen()} />
  {/if}
</div>
